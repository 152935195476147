
import img1 from '../images/Brands/Avalanche White.png'
import img2 from '../images/Brands/MOS Tech Labs_ White-05.png'
import img3 from '../images/Brands/Pepe White.png'
import img4 from '../images/Brands/Ruglabz White.png'
import img5 from '../images/Brands/Stellio Logo.png'



const dataPartner = [
  {
    id: 1,
    img: img1,
    link: '',
  },
  {
    id: 2,
    img: img2,
    link: 'https://mostechlabs.com/',
  },
  {
    id: 3,
    img: img3,
    link: 'https://pepehatesnfts.com/',
  },
  {
    id: 4,
    img: img4,
    link: '',
  },
  {
      id: 5,
      img: img5,
      link: 'https://ruglabzproject.com/'
  },
];

export default dataPartner;