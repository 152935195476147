
import img1 from '../images/banners/Web Banner1.png'
import img2 from '../images/banners/Web Banner2.png'
import img3 from '../images/banners/Web Banner3.png'
import img4 from '../images/banners/Web Banner4.png'
import img5 from '../images/banners/Web Banner5.png'
import img6 from '../images/banners/Web Banner6.png'
import img7 from '../images/banners/Web Banner7.png'
import img8 from '../images/banners/Web Banner8.png'
import img9 from '../images/banners/Web Banner9.png'

import avt from '../images/layouts/avt-05.png'



const dataCard = [

    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 2,
        img: img2,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 3,
        img: img3,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 4,
        img: img4,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 5,
        img: img5,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 6,
        img: img6,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 7,
        img: img7,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 8,
        img: img8,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    },
    {
        id: 9,
        img: img9,
        title: '3D Digital Artwork',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
    }


]

export default dataCard;