
import img1 from '../images/layouts/project-01.jpeg'
import img2 from '../images/layouts/project-02.png'
import img3 from '../images/layouts/project-03.png'
import img4 from '../images/layouts/project-04.png'
import img5 from '../images/layouts/project-05.png'
import img6 from '../images/layouts/project-06.jpeg'
import img7 from '../images/layouts/project-07.png'
import img8 from '../images/layouts/project-08.jpg'
import img9 from '../images/layouts/project-09.png'
import img10 from '../images/layouts/project-10.jpeg'
import img11 from '../images/layouts/project-11.png'
import img12 from '../images/layouts/project-12.png'
import img13 from '../images/layouts/project-13.png'
import img14 from '../images/layouts/project-14.png'
import img15 from '../images/layouts/project-15.png'
import img16 from '../images/layouts/project-51.png'
import img17 from '../images/layouts/project-52.jpg'
import img18 from '../images/layouts/project-53.jpg'
import img19 from '../images/layouts/project-54.jpg'
import img20 from '../images/layouts/project-55.jpg'
import img21 from '../images/layouts/project-56.png'
import img22 from '../images/layouts/project-57.png'
import img23 from '../images/layouts/project-58.png'
import img24 from '../images/layouts/project-59.png'
import img25 from '../images/layouts/project-60.png'
import img26 from '../images/layouts/project-61.png'


const dataProject = [

    {
        id: 1,
        img: img1,
        title: 'AI Kid', url: '#', owner: 'AI Kid',
    },
    {
        id: 2,
        img: img2,
        title: 'RevengerZ', url: '#', owner: '',
    },
    // {
    //     id: 3,
    //     img: img3,
    //     title: 'Solvillains', url: 'https://magiceden.io/marketplace/vill', owner: '',
    // },
    {
        id: 4,
        img: img4,
        title: 'The Astronaut', url: '#', owner: '',
    },
    {
        id: 5,
        img: img5,
        title: 'RugDollz 3D', url: 'https://opensea.io/collection/rugdollz-3d', owner: '',
    },
    {
        id: 6,
        img: img6,
        title: 'NFTPD NFTs', url: '#', owner: '',
    },
    {
        id: 7,
        img: img7,
        title: 'Solvillains', url: 'https://magiceden.io/marketplace/vill', owner: '',
    },
    {
        id: 8,
        img: img8,
        title: 'The Crow Man', url: '#', owner: '',
    },
    // {
    //     id: 9,
    //     img: img9,
    //     title: 'Solvillains', url: 'https://magiceden.io/marketplace/vill', owner: '',
    // },
    // {
    //     id: 10,
    //     img: img10,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 11,
    //     img: img11,
    //     title: 'RugDollz 3D', url: 'https://opensea.io/collection/rugdollz-3d', owner: '',
    // },
    {
        id: 12,
        img: img12,
        title: 'Titan Takeover', url: '#', owner: 'https://titantakeover.com/',
    },
    {
        id: 13,
        img: img13,
        title: 'Samurai NFTs', url: '#', owner: '',
    },
    {
        id: 14,
        img: img14,
        title: 'Power of Zeus DAO', url: 'https://powerofzeusdao.com/', owner: '',
    },
    // {
    //     id: 15,
    //     img: img15,
    //     title: 'WAGMI', url: '#', owner: '',
    // },

    // {
    //     id: 16,
    //     img: img19,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 17,
    //     img: img17,
    //     title: 'Eagle', url: '#', owner: '',
    // },
    // {
    //     id: 18,
    //     img: img18,
    //     title: 'Crypto Scientist NFTs', url: '#', owner: '',
    // },
    // {
    //     id: 19,
    //     img: img19,
    //     title: 'Crypto Scientist NFTs', url: '#', owner: '',
    // },
    // {
    //     id: 20,
    //     img: img20,
    //     title: 'Crypto Scientist NFTs', url: '#', owner: '',
    // },
    // {
    //     id: 21,
    //     img: img21,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 22,
    //     img: img22,
    //     title: 'S', url: '#', owner: '',
    // },
    // {
    //     id: 23,
    //     img: img23,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 24,
    //     img: img24,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 25,
    //     img: img25,
    //     title: '', url: '#', owner: '',
    // },
    // {
    //     id: 26,
    //     img: img26,
    //     title: '', url: '#', owner: '',
    // },
]

export default dataProject;