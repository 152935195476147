


const dataFaqs = [
    {
        id: 1,
        title: 'What is an NFT?',
        text: 'An NFT (Non-Fungible Token) is a unique digital asset that represents ownership or proof of authenticity of a particular item or artwork on the blockchain.',
        show: ''

    },
    {
        id: 2,
        title: 'Why does NFT art have value? Why are NFTs so expensive?',
        text: `NFT art has value because of its uniqueness, proof of ownership, and the emotional connection individuals have with the artwork. The scarcity of NFTs and their verification on the blockchain contribute to their perceived worth. NFTs can be expensive due to factors such as their rarity, association with popular artists, market demand, and the historical significance they hold within the NFT ecosystem.The prices of NFTs are driven by market dynamics and the willingness of buyers to invest in these digital assets.`,
        show: ''

    },
    {
        id: 3,
        title: 'What are NFTs used for?',
        text: 'NFTs can be used to indicate ownership of rare assets which represents real-world items like real estate and artwork. It’s important to note however that purchasing an NFT that includes the copyright of the original artwork does not mean its copyright is automatically transferred to you. While you can sell the NFT itself, you are prohibited from reproducing or selling its content.',
        show: ''

    },
    {
        id: 4,
        title: 'Are NFTs a good investment?',
        text: `Yes, The investment potential of NFTs can vary and is subject to market conditions. While some NFTs have seen significant returns, it's important to approach NFTs as a speculative investment with inherent risks.Conduct thorough research, consider your financial goals, and be mindful of market volatility before investing in NFTs.`,
        show: ''

    },
    {
        id: 5,
        title: 'Who is NftPros?',
        text: `NFTPro is a renowned NFT Artist/Digital Artist with over 5 years of experience in various NFT-related services. With an impressive portfolio, they have successfully collaborated with over 450 clients from more than 30 countries worldwide. NFTPro's expertise and artistic vision make them a trusted choice for individuals and businesses seeking top- quality NFT services.`,
        show: ''

    },
    {
        id: 6,
        title: 'How can I buy an NFT Service from you?',
        text: 'To purchase an NFT service from NFTPros, You can contact from our contact section. Then we will guide you through the necessary steps to complete the your project.',
        show: ''

    },
    {
        id: 7,
        title: '',
        text: '',
        show: ''

    },
    {
        id: 8,
        title: '',
        text: '',
        show: ''

    },





]

export default dataFaqs;