import React, { useState, useEffect } from "react";
import About2 from "../components/about/About2";

import Project from "../components/project/Project";
import dataProject from "../assets/fake-data/dataProject";
import Roadmap from "../components/roadmap/Roadmap";
import dataTeam from "../assets/fake-data/dataTeam";
import Testimonials from "../components/testimonials/Testimonials";
import Faq3 from "../components/faqs/Faq3";
import dataFaqs from "../assets/fake-data/data-faq";
import Banner3 from "../components/banner/Banner3";
import About3 from "../components/about/About3";
import Token from "../components/token/Token";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import Team2 from "../components/team/Team2";
import Speciality from "../components/speciality/Speciality";
import dataBox from "../assets/fake-data/data-box";
import dataTestimonials from "../assets/fake-data/data-testimonials";
import Counter from "../components/counter/Counter";

import Footer from "../components/footer/Footer";
import SplashScreen from "./SplashScreen";
import Partner from "../components/partner/Partner";


function Home03(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to demonstrate the splash screen
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div className="home-3 wrapper">
          <Banner3 />
          <div id="aboutbanner"></div>
          <About3 />

          <Token />

          <Roadmap data={dataRoadMap} />
          <Partner/>
          <Project data={dataProject} />
          <Team2 data={dataTeam} />

          <Speciality data={dataBox} />

          <Testimonials data={dataTestimonials} />

          <Counter />
          {/* <Banner2 data={dataCard} /> */}

          {/* <Blog data={dataBlog} /> */}
          <div style={{ marginTop: "150px" }} />
          <Faq3 data={dataFaqs} />

          <Footer />
        </div>
      )}
    </>
  );
}

export default Home03;
