import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";
import img from '../assets/images/logo/favicon.png'
export default function SplashScreen() {
  return (
    <>
      <div
        className="splash-screen"
        // style={{
        //   width: "100vw",
        //   height: "100vh",
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        {/* <img
          src="https://NFTProsreact-eb8gshhgc-themesflat.vercel.app/static/media/banner.b41e8bd3384755f8ff18.png"
          alt="Splash Screen"
          //   style={{
          //       borderRadius: "50%",
          //       width: 160,
          //   }}
        /> */}
        <LazyLoadImage alt="NFTPros" effect="blur" src={img} />
      </div>
    </>
  );
}
