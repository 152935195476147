
import icon1 from '../images/icon/quote.png'
import profile4 from '../images/layouts/Profile4.jpg';
import star from '../images/layouts/star.png'
import profile1 from '../images/layouts/Profile1.jpg'
import profile2 from '../images/layouts/Profile2.jpg'
import profile3 from '../images/layouts/Profile3.jpg'
const dataTestimonials = [
  {
    id: 1,
    icon: icon1,
    text: '“ Sphiria Digital did an amazing job designing NFTs for our project. From the beginning, their customer service was top-notch—they answered all of our questions quickly and efficiently. We were thoroughly impressed with their level of attention to detail and the quality of their work. ”',
    avt: profile3,
    name: 'Stellie',
    position: 'CEO OF BLOCKCHAT DAO',
    rating: star,
  },
  {
    id: 2,
    icon: icon1,
    text: `“ If you're in need of a talented and reliable web developer, look no further than Sphiria Digital.I recently worked with them on a website development project and was blown away by their skills, professionalism, and attention to detail.I have no hesitation in recommending Sphiria Digital to anyone in need of a top- notch web developer.Thank you for your hard work and for delivering a website that exceeded my expectations! ”`,
    avt: profile4,
    name: 'Alex',
    position: 'FOUNDER OF RUG LABS',
    rating: star,
  },
  {
    id: 3,
    icon: icon1,
    text: '“ Absolutely perfect! I am 100% satisfied with the work that was done. A+ job. The artwork was amazing such talent. The communication was top notch and I really appreciated the patience as we did revisions to get it just right. I will be coming back to create more in the future. I highly recommend it. Thanks again for helping to make my dream a reality. ”',
    avt: profile1,
    name: 'Raouf Naguib',
    position: 'FOUNDER OF RNO',
    rating: star,
  },
  {
    id: 3,
    icon: icon1,
    text: '“ I would highly recommend Sphiria Digital Studio to anyone looking for a talented and reliable digital artist. They truly went above and beyond to deliver a product that exceeded my expectations. Thank you for all your hard work! ”',
    avt: profile2,
    name: 'Pat Castellar',
    position: 'FOUNDER OF PEPE HATES NFTS',
    rating: star,
  },
];

export default dataTestimonials;