import img1 from '../images/layouts/team-01.png';
import img2 from '../images/layouts/team-02.png';
import img3 from '../images/layouts/team-03.png';
import img4 from '../images/layouts/team-04.png';
import img5 from '../images/layouts/team-05.png';
import img7 from '../images/layouts/team-07.png';
import img8 from '../images/layouts/team-08.png';
import t1 from '../images/team/Avishka.png';
import t9 from '../images/team/Bagya.png';
import t12 from '../images/team/Charlie.png';
import t13 from '../images/team/Didula.png';
import t7 from '../images/team/Kasun.png';
import t8 from '../images/team/Kavinda.png';
import t2 from '../images/team/Pasan.png';
import t11 from '../images/team/Pasan2.png';
import t10 from '../images/team/Prathibha.png';
import t3 from '../images/team/Rohan.png';
import t4 from '../images/team/Tharushi.png';
import t6 from '../images/team/Thison.png';

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: 'Avishka Rathnayake',
    position: 'Founder and CEO (Chief Executive Officer)',
    linked: t1,
    url: 'https://www.linkedin.com/in/avishka-rath/',
  },
  {
    id: 2,
    img: img2,
    name: 'Pasan Wijerathne',
    position: 'Founder',
    linked: t2,
    url: 'https://www.linkedin.com/in/pasan-mihindu-2a6403131/',
  },
  {
    id: 3,
    img: img2,
    name: 'Rohan Perera',
    position: 'Chief Marketing Officer',
    linked: t3,
    url: 'https://www.linkedin.com/in/samantharohanperera',
  },
  {
    id: 4,
    img: img3,
    name: 'Tharushika Herath',
    position: 'Head of Operations',
    linked: t4,
    url: 'https://www.linkedin.com/in/tharushika-herath-65195517a/',
  },
  // {
  //   id: 5,
  //   img: img4,
  //   name: 'Matheesha Perera',
  //   position: 'Head of Digital Marketing',
  //   linked: t5,
  //   url: '',
  // },
  {
    id: 6,
    img: img4,
    name: 'Thilan Udadeniya',
    position: 'Art Director',
    linked: t12,
    url: '',
  },
  {
    id: 7,
    img: img5,
    name: 'Thison Nayanake',
    position: 'Senior Digital Artist',
    linked: t6,
    url: '',
  },
  {
    id: 8,
    img: img7,
    name: 'Kasun Gunarathne',
    position: 'Concept Artist',
    linked: t7,
    url: '',
  },
  {
    id: 9,
    img: img7,
    name: 'Kavinda Manohara',
    position: 'Digital Artist',
    linked: t8,
    url: '',
  },
  {
    id: 10,
    img: img8,
    name: 'Pamudi Bhagya',
    position: 'Digital Artist',
    linked: t9,
    url: '',
  },
  // {
  //   id: 11,
  //   img: img8,
  //   name: 'Gihantha Gunasekara',
  //   position: 'Digital Artist',
  //   linked: t11,
  //   url: '',
  // },
  {
    id: 12,
    img: img4,
    name: 'Prathibha Ratnayake',
    position: 'Software Engineer',
    linked: t10,
    url: '',
  },
  {
    id: 12,
    img: img4,
    name: 'Pasan Ratnayake',
    position: 'Trainee Software Engineer',
    linked: t11,
    url: '',
  },
  {
    id: 13,
    img: img1,
    name: 'Didula Dishan',
    position: 'Front End Developer',
    linked: t13,
    url: '',
  },
];

export default dataTeam;
