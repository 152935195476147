

const dataRoadMap = [

    {
        id: 1,
        time: 'Step 01',
        title: 'Initiation & Planning',
        desc: 'Identifying the goals and objectives, and team involves researching and gathering relevant information user needs, market trends, technical requirements and other constraints.',
        class: ''
    },
    {
        id: 2,
        time: 'Step 02',
        title: 'Design & Process',
        desc: 'The team creates prototypes of the concept, which could be sketches, wireframes, mockups, or working models. Prototyping helps to test the concept and gather feedback from stakeholders.',
        class: 'right'
    },

    {
        id: 3,
        time: 'Step 03',
        title: 'Development',
        desc: 'This stage involves putting the plan into action. The project team is assembled, and the work is assigned to various members and involves developing. monitoring progress, resolving issues, and managing changes',
        class: ''
    },

    {
        id: 4,
        time: 'Step 04',
        title: 'Launching',
        desc: 'Finally, the final Product is implemented. and the project is launched. The implementation process may involve further testing, user training, detailed documentation, and other activities to ensure a successful launch.',
        class: 'right'
    },

]

export default dataRoadMap;