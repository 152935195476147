
import lordsoflemuria from '../images/web-dapp/Web-banner-lom.png';
import pooh from '../images/web-dapp/web-banner-pooh.png'
import alphaSkier from '../images/web-dapp/web-banner alphaSkier.png';
import miners from '../images/web-dapp/web-banner-miners.png';
import peter from '../images/web-dapp/web-banner-peter-obi.png';
import zeus from '../images/web-dapp/web-banner-powerofzeusdao.png';
import titan from '../images/web-dapp/web-banner-titantakeover.png';
import zalien from '../images/web-dapp/web-banner-zalienSpace.png';
import zkdoge from '../images/web-dapp/web-banner-kdoge.png';
import aiKids from '../images/2d/Ai Kids by NFTPros.jpeg'
import astronaut from '../images/2d/Astronaut by NFTPros.jpg';
import coolCat from '../images/2d/Cool Cat by NFTPros.png';
import cryptoMiami from '../images/2d/Crypto Miami by NFTPros.jpeg';
import eagle from '../images/2d/Eagle  by NFTPros.jpg'
import gangsterCat from '../images/2d/Gangser Cat by NFTPros.png'
import gargoyle from '../images/2d/Gargoyle by NFTPros.jpeg'
import hyena from '../images/2d/Hyena by NFTPros.png'
import imperialSamurai from '../images/2d/Imperial Samurai by NFTPros.png'
import kingSkorn from '../images/2d/King Skorn by NFTPros.jpg'
import lordErron from '../images/2d/Lord Erron by NFTPros.jpg'
import madMax from '../images/2d/MadMax by NFTPros.png'
import maskPunk from '../images/2d/Mask Punk by NFTPros.jpg'
import miner from '../images/2d/Miner by NFTPros.jpg'
import mummy from '../images/2d/Mummy by NFTPros.png'
import nfptdSniper from '../images/2d/NFTPD Sniper  by NFTPros.jpeg'
import nftpdTrippy from '../images/2d/NFTPD Trippy by NFTPros.png'
import panda from '../images/2d/Panda ChinaTown by NFTPros.jpg'
import pepe from '../images/2d/Pepe Hates NFTs by NFTPros.jpg'
import powerOfZeus from '../images/2d/Power of Zeus DAO by NFTPros.png'
import princeDolf from '../images/2d/Prince Dolf by NFTPros.jpg';
import revengerz from '../images/2d/RevengerZ by NFTPros.jpg'
import samurai from '../images/2d/Samurai by NFTPros.png'
import samuraiCat from '../images/2d/Samurai Cat by NFTPros.png'
import scientist from '../images/2d/Scientist by NFTPros.jpg'
import sheep from '../images/2d/Sheeple People Christian Club by NFTPros.jpg'
import solvillians from '../images/2d/Solvillains by NFTPros.jpg';
import wagmi from '../images/2d/WAGMI  by NFTPros.jpg';
import wizard from '../images/2d/Wizard by NFTPros.png';
import xigouInu from '../images/2d/Xigou Inu by NFTPros.jpg';
import rugdolzzSocial from '../images/2d/RugDollz Social.png'
import rugdolzzNft from '../images/2d/RugDollz NFT.png';
import spaceman from '../images/2d/SpaceMan Venus by NFTPros.jpg'
import rno from '../images/2d/RNO by NFTPros.png';
import spacemanMercury from '../images/2d/SpaceMan Mercury by NFTPros.jpg';
import archerGirl from '../images/anime/Archer Girl by NFTPros.jpeg'
import archerswap from '../images/anime/Archerswap by NFTPros.jpeg';
import dragonBall from '../images/anime/DragonBall by NFTPros.jpeg'
import ezekiel from '../images/anime/Ezekiel by NFTPros.jpeg'
import kidou from '../images/anime/Kidou by NFTPros.jpeg'
import maskGirl from '../images/anime/Mask Girl by NFTPros.jpg'
import mermaid from '../images/anime/Mermaid by NFTPros.jpg'
import yungun from '../images/anime/YunGun by NFTPros.png'
import s80s from '../images/3D/80s by NFTPros.jpg'
import coolAlien from '../images/3D/Cool Alian by NFTPros.jpeg'
import lom from '../images/3D/Lords of Lemuria by NFTPros.jpg';
import rugDollz1 from '../images/3D/RugDollz1 by NFTPros.jpg';
import rugDollz from '../images/3D/RugRollz by NFTPros.jpg';
import zalien2 from '../images/3D/Zalien by NFTPros.jpeg'
import anarchyInTheUK from '../images/realistic/Anarchy in the UK by NFTPros.jpg'
import crowMan from '../images/realistic/Crow man by NFTPros.jpg'
import goldRat from '../images/realistic/Gold Rat by NFTPros.jpg'
import leopardRat from '../images/realistic/Leopard Rat by NFTPros.jpg';
import lsdRat from '../images/realistic/LSD Rat by NFTPros.jpg';
import nebulaRat from '../images/realistic/Nebula Rat by NFTPros.jpg';
import rabbit from '../images/realistic/Rabbit by NFTPros.jpg'
import radioactive from '../images/realistic/Radioactive by NFTPros.jpg';
import bear from '../images/realistic/The Bear by NFTPros.jpeg'
import bull from '../images/realistic/The Bull by NFTPros.jpg';
import tinker from '../images/realistic/Tinker by NFTPros.jpg'
import weedBear from '../images/realistic/Weed Bear by NFTPros.jpg';

const dataItem = [
  {
    id: 1,
    img: lordsoflemuria,
    title: 'Lords of Lemuria',
    url: 'https://lordsoflemuria.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 2,
    img: pooh,
    title: 'POOH NFTS',
    url: 'https://poohnfts.net/',
    owner: '',
    type: 'web',
  },
  {
    id: 3,
    img: alphaSkier,
    title: 'ALPHA SKIER Club',
    url: 'https://alphaskierclub.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 4,
    img: miners,
    title: 'World Data Miners',
    url: 'https://worlddataminers.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 5,
    img: peter,
    title: 'PERER OBI NFT',
    url: 'https://obidientnftartinitiative.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 6,
    img: zeus,
    title: 'POWER OF ZEUS DAO',
    url: 'https://powerofzeusdao.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 7,
    img: titan,
    title: 'TITAN TAKEOVER',
    url: 'https://titantakeover.com/',
    owner: '',
    type: 'web',
  },
  {
    id: 8,
    img: zalien,
    title: 'ZALIEN',
    url: 'https://www.zalien.space/',
    owner: '',
    type: 'web',
  },
  {
    id: 9,
    img: zkdoge,
    title: 'zkdoge',
    url: 'https://zkdoge.org/',
    owner: '',
    type: 'web',
  },

  {
    id: 10,
    img: aiKids,
    title: 'AI Kids',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 11,
    img: astronaut,
    title: 'Astronaut',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 12,
    img: coolCat,
    title: 'Cool Cat',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 13,
    img: cryptoMiami,
    title: 'Crypto Miami',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 14,
    img: eagle,
    title: 'Eagle',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 15,
    img: gangsterCat,
    title: 'Gangster Cat',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 16,
    img: gargoyle,
    title: 'Gargoyle',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 17,
    img: hyena,
    title: 'Hyena',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 18,
    img: imperialSamurai,
    title: 'Imperial Samurai',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 19,
    img: kingSkorn,
    title: 'King Skorn',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 20,
    img: lordErron,
    title: 'Lord Erron',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 21,
    img: madMax,
    title: 'Mad Max',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 22,
    img: maskPunk,
    title: 'Mask Punk',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 23,
    img: miner,
    title: 'Miner',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 24,
    img: mummy,
    title: 'Mummy',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 25,
    img: nfptdSniper,
    title: 'NFTPD Sniper',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 26,
    img: nftpdTrippy,
    title: 'NFTPD Trippy',
    url: '',
    owner: '',
    type: '2d',
  },
  // {
  //   id: 27,
  //   img: panda,
  //   title: 'Panda ChinaTown',
  //   url: '',
  //   owner: '',
  //   type: '2d',
  // },
  {
    id: 28,
    img: pepe,
    title: 'Pepe Hates NFTs',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 29,
    img: powerOfZeus,
    title: 'Power of Zeus DAO',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 30,
    img: princeDolf,
    title: 'Prince Dolf',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 31,
    img: revengerz,
    title: 'revengerZ',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 32,
    img: samurai,
    title: 'Samurai',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 33,
    img: samuraiCat,
    title: 'Samurai Cat',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 34,
    img: scientist,
    title: 'Scientist',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 35,
    img: sheep,
    title: 'Sheeple People Christian Club ',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 36,
    img: solvillians,
    title: 'Solvillians',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 37,
    img: wagmi,
    title: 'WAGMI',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 38,
    img: wizard,
    title: 'Wizard',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 39,
    img: xigouInu,
    title: 'Xigou Inu',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 40,
    img: rugdolzzSocial,
    title: 'RugDollz Social',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 41,
    img: rugdolzzNft,
    title: 'RugDollz NFT',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 42,
    img: rno,
    title: 'RNO',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 43,
    img: spaceman,
    title: 'SpaceMan Venus',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 44,
    img: spacemanMercury,
    title: 'SpaceMan Mercury',
    url: '',
    owner: '',
    type: '2d',
  },
  {
    id: 45,
    img: archerGirl,
    title: 'Archer Girl',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 46,
    img: archerswap,
    title: 'Archerswap',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 47,
    img: dragonBall,
    title: 'DragonBall',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 48,
    img: ezekiel,
    title: 'Ezekiel',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 49,
    img: kidou,
    title: 'Kidou',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 50,
    img: maskGirl,
    title: 'Mask Girl',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 51,
    img: mermaid,
    title: 'Mermaid',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 52,
    img: yungun,
    title: 'YunGun',
    url: '',
    owner: '',
    type: 'anime',
  },
  {
    id: 53,
    img: s80s,
    title: '80s',
    type: '3d',
  },
  {
    id: 54,
    img: coolAlien,
    title: 'Cool Alien',
    type: '3d',
  },
  {
    id: 55,
    img: lom,
    title: 'Loards of Lumuria',
    type: '3d',
  },
  {
    id: 56,
    img: rugDollz1,
    title: 'RugDollz',
    type: '3d',
  },
  {
    id: 57,
    img: rugDollz,
    title: 'RugDollz',
    url: '',
    owner: '',
    type: '3d',
  },
  {
    id: 58,
    img: zalien2,
    title: 'Zalien',
    url: '',
    owner: '',
    type: '3d',
  },
  {
    id: 59,
    img: anarchyInTheUK,
    title: 'Anarchy in the UK',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 60,
    img: crowMan,
    title: 'Crow man',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 61,
    img: goldRat,
    title: 'Gold Rat',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 62,
    img: leopardRat,
    title: 'Leopard Rat',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 63,
    img: lsdRat,
    title: 'LSD Rat',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 64,
    img: nebulaRat,
    title: 'Nebula Rat',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 65,
    img: bear,
    title: 'The Bear',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 66,
    img: rabbit,
    title: 'Rabbit',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 67,
    img: radioactive,
    title: 'Radioactive',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 68,
    img: bull,
    title: 'The Bull',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 69,
    img: tinker,
    title: 'Tinker',
    url: '',
    owner: '',
    type: 'realistic',
  },
  {
    id: 70,
    img: weedBear,
    title: 'Weed Bear',
    url: '',
    owner: '',
    type: 'realistic',
  },
];

export default dataItem;