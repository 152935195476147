
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Huge collection',
        desc: 'We have large number of collections successfully completed with best feedbacks from clients. '
    },
    {
        id: 2,
        img: img2,
        title: 'High quality',
        desc: 'Our professionals using softwares and tools to get the maximum output of the project. Projects are created to cater to unique needs and desires of clients.'
    },
    {
        id: 3,
        img: img3,
        title: 'Top Resource',
        desc: 'All work are created by professionally qualified team members such as Digital Artists, software Engineers, Front end developers and UI/ UX designers. '
    },
    {
        id: 4,
        img: img4,
        title: 'Big Community',
        desc: 'NFTPros provides services to all over the world. We expand our market among Sri Lanka, USA, Canada and Australia'
    },

]

export default dataBox;