/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";
import data from "../../assets/fake-data/dataPartner";

function Partner(props) {
  return (
    <div
      className="brands block-text center"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <h6 className="sub-heading">
        <span>We Are Partnered with Top Brands</span>
      </h6>

      <Swiper
        className="brands-swiper"
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          500: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 6,
          },
        }}
        loop={true}
      >
        {data.map((idx) => (
          <SwiperSlide key={idx.id}>
            <a href={idx.link} target="_blank">
              <LazyLoadImage alt="NFTPros" effect="blur" src={idx.img}  className="logos-partners" height={80} />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Partner;
