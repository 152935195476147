import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import img from '../../assets/images/layouts/Wolf Character 2.4.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Banner3(props) {
  const Counter = ({ value, label }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (count < value) {
          setCount((prevCount) => prevCount + 1);
        }
      }, 5);

      return () => clearInterval(interval);
    }, [count, value]);

    return (
      <motion.div
        className="border-[#5BACE3] border-r-4 overflow-hidden"
        style={{ width: '150px' }}
      >
        <motion.h4
          className="font-bold text-[96px] text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {count}+
        </motion.h4>
        <motion.p
          className="font-medium text-[32px] text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {label}
        </motion.p>
      </motion.div>
    );
  };

  const Typewriter = ({ text }) => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
      let charIndex = 0;

      const intervalId = setInterval(() => {
        if (charIndex <= text.length) {
          setDisplayText(text.substring(0, charIndex));
          charIndex++;
        } else {
          clearInterval(intervalId);
        }
      }, 40); // Adjust the interval speed as needed for smoother animation

      return () => clearInterval(intervalId);
    }, [text]);

    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }} // Adjust duration for smoother animation
        >
          {displayText}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <section className="banner s3">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="shape s3 right"></div>

      <div className="container big">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div className="banner__left">
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className="block-text"
              >
                <h2 className="heading">
                  {/* Discover and Collect Digital Art */}
                  <span className="arlo_tm_animation_text_word"></span>
                  <Typewriter text="Discover and Collect Digital Art" />
                </h2>
                <p className="desc">
                  Empower your brand with our exquisite NFT Art and Web 3.0
                  solutions.
                </p>

                <Link to="/contact" className="action-btn">
                  <span>Let's Chat</span>
                </Link>
              </motion.div>

              <div className="couter-list">
                <div className="couter-box">
                  <Counter value={400} label="Clients" />
                </div>
                <div className="couter-box">
                  <Counter value={30} label="Countries" />
                </div>
                <div className="couter-box">
                  <Counter value={35} label="Artists" />
                </div>
                <div className="couter-box">
                  <Counter value={5} label="Era" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div className="banner__right">
              <div className="image-1">
                <LazyLoadImage alt="NFTPros" effect="blur" src={img} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner3;
