import './styles.scss'

const Filter = ({ data, setFilteredData }) => {
  
  const handleFilter = (type) => {
    let filtered;
    if (type === '') {
      filtered = data;
    } else {
      filtered = data.filter((item) => item.type === type);
    }
    setFilteredData(filtered);
  };

  return (
    <div className="filter-container">
      <button className="action-btn" onClick={() => handleFilter('')}>All</button>
      <button className="action-btn" onClick={() => handleFilter('2d')}>2D Cartoons</button>
      <button className="action-btn" onClick={() => handleFilter('3d')}>3D</button>
      <button className="action-btn" onClick={() => handleFilter('realistic')}>Realistic</button>
      <button className="action-btn" onClick={() => handleFilter('anime')}>Anime</button>
      <button className="action-btn" onClick={() => handleFilter('web')}>Minting Website & DApp</button>
    </div>
  )
}

export default Filter