import React, { useState } from "react";

import Button from "../button/Button";

import img1 from "../../assets/images/layouts/project-16.jpg";
import img2 from "../../assets/images/layouts/Rat.gif";

import avt from "../../assets/images/layouts/avt-05.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";
function About3(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Design Digital Products for world changers",
    desc1:
      "Welcome to NFTPros, where cutting-edge NFT design meets innovation. We empower artists, creators, and collectors with tools and expertise to thrive in the evolving NFT ecosystem. Our creative hub combines visionary artists, designers, and technologists to push boundaries in NFT art. Join us in shaping the future of art through NFTs, unleashing creativity and offering unique, valuable creations.",
    desc2:
      "NFTPros is a leading NFT design studio with over 5 years of experience in providing comprehensive NFT-related services. Our team of experts has had the privilege of collaborating with more than 450 clients from over 30 countries worldwide",
  });
  const [dataCard] = useState([
    {
      id: 1,
      img: img2,
      title: "2D Realistic Artwork",
      price: "",
      avt: avt,
      name: "Punk Rats",
      tag: "@NFTPros",
    },
    {
      id: 2,
      img: img1,
      title: "2D Digital Artwork",
      price: "",
      avt: avt,
      name: "Xigou Inu",
      tag: "@NFTPros",
      class: "sm",
    },
  ]);
  return (
    <section className="about s3">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-md-12">
            <div className="block-text pd-0">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc1}</p>
              <p className="mb-26">{dataBlock.desc2}</p>
              <Button title="Contact Us" link="/contact" />
            </div>
          </div>
          <div className="col-xl-3 col-md-12">
            <div
              className="about__right"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              {dataCard.map((idx) => (
                <div key={idx.id} className={`card-box ${idx.class}`}>
                  <div className="top d-flex">
                    <span className="icon-logo-01"></span>
                    <div>
                      <h6>{idx.title}</h6>
                      <h6 className="price">{idx.price}</h6>
                    </div>
                  </div>
                  <div className="content">
                    <div className="image">
                      <LazyLoadImage
                        alt="NFTPros"
                        effect="blur"
                        src={idx.img}
                      />
                    </div>

                    <div className="info d-flex">
                      <img src={idx.avt} alt="NFTPros" />

                      <div>
                        <h6 className="name">{idx.name}</h6>
                        <p>{idx.tag}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About3;
