import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import img from "../../assets/images/layouts/avt-05.png";
import Filter from "../filter/Filter";
Project3.propTypes = {
  data: PropTypes.array,
};

function Project3(props) {
  const { data } = props;
  const [likes, setLikes] = useState([]);
  const [filteredData, setFilteredData] = useState(data);


  useEffect(() => {
    const randomLikes = data.map(() => Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000);
    setLikes(randomLikes);
  }, [data]);

  return (
    <section className="nft">
      <div className="container">
        <div className="row">
          <div className="col-12">
          </div>

          {/* filter buttons */}
          <Filter data={data} setFilteredData={setFilteredData} />

          {filteredData.map((idx, index) => (
            <div key={idx.id} className="col-xl-3 col-md-6">
              <div className="nft-item">
                <div className="card-media">
                  <a href={idx.url} target="_blank" rel="noreferrer">
                    {/* <img src={idx.img} alt="NFTPros" /> */}
                    <LazyLoadImage alt="NFTPros" effect="blur" src={idx.img} />
                  </a>
                </div>
                <div className="card-title">
                  <Link to="#" className="h5">
                    {idx.title}
                  </Link>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="avatar">
                      {/* <img src={img} alt="NFTPros" /> */}
                      <LazyLoadImage
                        alt="NFTPros"
                        effect="blur"
                        src={img}
                      />
                    </div>
                    <div className="info">
                      <span>Design By</span>
                      <Link to="#" className="h6">
                        NFTPros
                      </Link>
                    </div>
                  </div>
                  <Link to="#" className="wishlist-button heart">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.75 4.3125C11.75 2.86292 10.5256 1.6875 9.01533 1.6875C7.88658 1.6875 6.91708 2.34433 6.5 3.28175C6.08292 2.34433 5.11342 1.6875 3.98408 1.6875C2.475 1.6875 1.25 2.86292 1.25 4.3125C1.25 8.52417 6.5 11.3125 6.5 11.3125C6.5 11.3125 11.75 8.52417 11.75 4.3125Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="number-like"> {likes[index]}</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project3;

