import React, { useEffect, useState } from "react";
import dataItem from "../assets/fake-data/data-item";
import dataProject from "../assets/fake-data/dataProject";
import Footer from "../components/footer/Footer";
import PageTitle from "../components/pagetitle/PageTitle";
import Project from "../components/project/Project";
import Project3 from "../components/project/Project3";
import SplashScreen from "./SplashScreen";
function Nfts(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to demonstrate the splash screen
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div className="wrapper">
          <PageTitle title="Portfolio" />
          <Project3 data={dataItem} />
          <Project data={dataProject} />
          <Footer />
        </div>
      )}
    </>
  );
}

export default Nfts;
